import { z } from 'zod';
import { makeFieldSchemaFromZod } from '../utils';

/**
 * @public
 */
export const prefixedTextFilterExpressionSchema = z.record(
  z
    .string()
    .or(z.object({ exists: z.boolean().optional() }))
    .or(z.array(z.string())),
);

/**
 * @public
 */
export const PrefixedTextFieldSchema = makeFieldSchemaFromZod(
  z.string(),
);

/**
 * The input props that can be specified under `ui:options` for the
 * `EntityPicker` field extension.
 *
 * @public
 */
export type PrefixedTextUiOptions =
  typeof PrefixedTextFieldSchema.uiOptionsType;

export type PrefixedTextProps = typeof PrefixedTextFieldSchema.type;

export const PrefixedTextSchema = PrefixedTextFieldSchema.schema;

export type PrefixedTextFilterQuery = z.TypeOf<
  typeof prefixedTextFilterExpressionSchema
>;

export type PrefixedTextFilterQueryValue =
PrefixedTextFilterQuery[keyof PrefixedTextFilterQuery];
