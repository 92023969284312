import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  PrefixedTextSchema,
  PrefixedTextField,
} from './textFieldDataExtension';

export const PrefixedTextFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: PrefixedTextField,
    name: 'PrefixedTextField',
    schema: PrefixedTextSchema,
  }),
);
