import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const nspStatusPlugin = createPlugin({
  id: 'nsp-status',
  routes: {
    root: rootRouteRef,
  },
});

export const NspStatusPage = nspStatusPlugin.provide(
  createRoutableExtension({
    name: 'NspStatusPage',
    component: () =>
      import('./pages/Landing').then(m => m.NspStatusPage),
    mountPoint: rootRouteRef,
  }),
);
