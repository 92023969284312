import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'nsp-launchpad',
});

export const statusRouteRef = createRouteRef({
  id: 'nsp-launchpad-status',
  params: ['workflowId']
});
