import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const nspLaunchpadPlugin = createPlugin({
  id: 'nsp-launchpad',
  routes: {
    root: rootRouteRef,
  },
});

export const NspLaunchpadPage = nspLaunchpadPlugin.provide(
  createRoutableExtension({
    name: 'NspLaunchpadPage',
    component: () => import('./Pages/components/LaunchpadPage').then(m => m.LaunchpadPage),
    mountPoint: rootRouteRef,
  }),
);

export const NspLaunchpadStatusPage = nspLaunchpadPlugin.provide(
  createRoutableExtension({
    name: 'NspLaunchpadStatusPage',
    component: () =>
      import('./Pages/components/LaunchpadStatusPage').then(m => m.LaunchpadStatusPage),
    mountPoint: rootRouteRef,
  }),
);

export const NspLaunchpadOnboardingTracker = nspLaunchpadPlugin.provide(
  createRoutableExtension({
    name: 'NspLaunchpadOnboardingTracker',
    component: () => import('./Pages/components/Dashboard/Dashboard').then(m => m.Dashboard),
    mountPoint: rootRouteRef,
  }),
);
