import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { useEffect, useState } from 'react';
import useAsync from 'react-use/esm/useAsync';
import { DomainPickerProps } from './schema';
import Autocomplete from '@material-ui/lab/Autocomplete';

export { DomainPickerSchema } from './schema';
export const DomainPicker = (props: DomainPickerProps) => {
  const {
    onChange,
    schema: { title = 'Entity', description = 'An entity from the catalog' },
    required,
    rawErrors,
    formData,
    idSchema,
  } = props;

  const catalogApi = useApi(catalogApiRef);

  const { value: entities, loading } = useAsync(async () => {
    const { items } = await catalogApi.getEntities({
      filter: { kind: ['domain'] },
    });

    return items;
  });

  const onSelect = (event: any, value: any) => {
    if (event._reactName == 'onChange' || event._reactName == 'onClick') {
      setSelectedDomain(value);
    }
  };

  const [selectedDomain, setSelectedDomain] = useState<Entity | '' | undefined>('');

  useEffect(() => {
    const selectedName = selectedDomain?.['metadata']?.name;
    const selectedAbbreviation = selectedDomain?.['metadata']?.annotations.abbreviation;
    const selectedGhDomain = selectedDomain?.['metadata']?.annotations.ghDomain;
    const newEntityVal = selectedName
      ? [selectedAbbreviation ?? selectedName, selectedGhDomain ?? selectedName]
      : '';
    onChange([...newEntityVal]);
  }, [selectedDomain]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <FormControl margin="normal" required={required} error={rawErrors?.length > 0 && !formData}>
        <Autocomplete
          disabled={entities?.length === 1}
          id={idSchema?.$id}
          value={selectedDomain}
          loading={loading}
          onChange={onSelect}
          options={entities || []}
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.metadata.title || option.metadata.name
          }
          autoSelect
          renderInput={params => (
            <TextField
              {...params}
              label={title}
              margin="dense"
              helperText={description}
              FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
