export const FILTER_PROPERTIES = [
    'author',
    'change.state',
    'repository.name',
    'change.approvers',
    'change.mergedBy',
    'environments',
    'regions',
    'types',
    'manifestType'
]
