import { FlatRoutes } from '@backstage/core-app-api';
import { ErrorPage } from '@backstage/core-components';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogIndexPage, CatalogEntityPage } from '@backstage/plugin-catalog';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { CatalogImportPage } from '@backstage/plugin-catalog-import';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';

import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  DefaultTechDocsHome,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import {
  ReportIssue,
  ExpandableNavigation,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { ConfigCatFeatureFlag } from '@internal/plugin-config-cat';
import {
  NspLaunchpadPage,
  NspLaunchpadStatusPage,
  NspLaunchpadOnboardingTracker,
} from '@internal/plugin-nsp-launchpad';
import { ChangeDetailsPage, ChangeListPage } from '@internal/plugin-trebuchet';
import { Route } from 'react-router-dom';
import { entityPage } from './components/catalog/EntityPage';
import { ChatPage } from './components/chat/ChatPage';
import { GlossaryPage } from './components/glossary/GlossaryPage';
import { HomePage } from './components/home/HomePage';
import { LearningPaths } from './components/learning-paths/LearningPaths';
import { NewsPage } from './components/news/NewsPage';
import { DomainPickerFieldExtension } from './components/scaffolder/DomainPicker';
import { PrefixedTextFieldExtension } from './components/scaffolder/PrefixedTextField';
import { searchPage } from './components/search/SearchPage';
import { SavingsPage } from './components/savings-tracker/SavingsTracker';
import { NspStatusPage } from '@internal/backstage-plugin-nsp-status';

export const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>

    <Route path="/news" element={<NewsPage />}></Route>

    <Route path="/glossary" element={<GlossaryPage />}></Route>

    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <ReportIssue />
        <ExpandableNavigation />
        <TextSize />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity => entity?.metadata?.tags?.includes('recommended') ?? false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <DomainPickerFieldExtension />
        <PrefixedTextFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/tech-radar" element={<TechRadarPage width={1500} height={800} />} />
    <Route path="/chat" element={<ChatPage />} />
    <Route
      path="/trebuchet"
      element={
        <ConfigCatFeatureFlag
          flag="trebuchet-enabled"
          disabledComponent={<ErrorPage status={'404'} statusMessage={'PAGE NOT FOUND'} />}
        >
          <ChangeListPage />
        </ConfigCatFeatureFlag>
      }
    />
    <Route
      path="/trebuchet/:manifestId"
      element={
        <ConfigCatFeatureFlag
          flag="trebuchet-enabled"
          disabledComponent={<ErrorPage status={'404'} statusMessage={'PAGE NOT FOUND'} />}
        >
          <ChangeDetailsPage />
        </ConfigCatFeatureFlag>
      }
    />

    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/learning-paths" element={<LearningPaths />} />
    <Route
      path="/nsp-launchpad/start"
      element={
        <ConfigCatFeatureFlag
          flag="launch-pad-enabled"
          disabledComponent={<ErrorPage status={'404'} statusMessage={'PAGE NOT FOUND'} />}
        >
          <NspLaunchpadPage />
        </ConfigCatFeatureFlag>
      }
    />
    <Route
      path="/nsp-launchpad/status/:workflowId"
      element={
        <ConfigCatFeatureFlag
          flag="launch-pad-enabled"
          disabledComponent={<ErrorPage status={'404'} statusMessage={'PAGE NOT FOUND'} />}
        >
          <NspLaunchpadStatusPage />
        </ConfigCatFeatureFlag>
      }
    />
    <Route
      path="/nsp-launchpad/onboarding-tracker"
      element={
        <ConfigCatFeatureFlag
          flag="nsp-onboarding-dashboard"
          disabledComponent={<ErrorPage status={'404'} statusMessage={'PAGE NOT FOUND'} />}
        >
          <NspLaunchpadOnboardingTracker />
        </ConfigCatFeatureFlag>
      }
    />

    <Route
      path="/savings-tracker"
      element={
        <ConfigCatFeatureFlag
          flag="savings-tracker-enabled"
          disabledComponent={<ErrorPage status={'404'} statusMessage={'PAGE NOT FOUND'} />}
        >
          <SavingsPage />
        </ConfigCatFeatureFlag>
      }
    />

<Route
      path="/nsp-status"
      element={
        <ConfigCatFeatureFlag
          flag="nsp-status-enabled"
          disabledComponent={<ErrorPage status={'404'} statusMessage={'PAGE NOT FOUND'} />}
        >
        <NspStatusPage />

        </ConfigCatFeatureFlag>

      }
    />
  </FlatRoutes>
);
