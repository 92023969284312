import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { techdocsPlugin } from '@backstage/plugin-techdocs';

import { apis } from './apis';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter } from '@backstage/core-app-api';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { SignInProviderConfig, SignInPage } from '@backstage/core-components';

import { UnifiedThemeProvider } from '@backstage/theme';
import { nintexLightTheme } from './themes/nintex-lightTheme';
import { nintexDarkTheme } from './themes/nintex-darkTheme';
import LightIcon from '@mui/icons-material/WbSunny';
import DarkIcon from '@mui/icons-material/Brightness2';
import { badgesPlugin } from '@backstage-community/plugin-badges';
import { configCatPlugin } from '@internal/plugin-config-cat';
import { routes } from './routes';

const microsoftProvider: SignInProviderConfig = {
  id: 'microsoft-auth-provider',
  title: 'Nintex SSO',
  message: 'Login with Nintex SSO',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  plugins: [badgesPlugin, configCatPlugin],
  components: {
    SignInPage: props => <SignInPage {...props} auto providers={[microsoftProvider]} />,
  },
  themes: [
    {
      id: 'nintex-light',
      title: 'Nintex Light',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={nintexLightTheme} children={children} />
      ),
    },
    {
      id: 'nintex-dark',
      title: 'Nintex Dark',
      variant: 'dark',
      icon: <DarkIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={nintexDarkTheme} children={children} />
      ),
    },
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
