import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

export class TechRadarClient implements TechRadarApi {
  async load(): Promise<TechRadarLoaderResponse> {
    const entries = (await fetch(
      'https://tectradar.blob.core.windows.net/publicviews/radar.json',
    ).then(res => res.json())) as RadarEntry[];
    const rings = (await fetch(
      'https://tectradar.blob.core.windows.net/publicviews/rings.json',
    ).then(res => res.json())) as RadarRing[];
    const quadrants = (await fetch(
      'https://tectradar.blob.core.windows.net/publicviews/quadrants.json',
    ).then(res => res.json())) as RadarQuadrant[];

    return {
      entries: entries.map(entry => ({
        ...entry,
        timeline: entry.timeline.map(t => ({
          ...t,
          date: new Date(t.date),
        })),
      })),
      rings,
      quadrants,
    };
  }
}
