import { PageTheme, genPageTheme, shapes } from '@backstage/theme';

export const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme({ colors: ['#ff6d00', '#e66b00'], shape: shapes.wave }),
  app: genPageTheme({ colors: ['#ff6d00', '#e66b00'], shape: shapes.wave }),
  apis: genPageTheme({ colors: ['#ff6d00', '#e66b00'], shape: shapes.wave }),
  documentation: genPageTheme({
    colors: ['#ff6d00', '#e66b00'],
    shape: shapes.wave,
  }),
  other: genPageTheme({ colors: ['#ff6d00', '#e66b00'], shape: shapes.wave }),
  tool: genPageTheme({ colors: ['#ff6d00', '#e66b00'], shape: shapes.round }),
};
