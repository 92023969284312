import {
  createApiFactory,
  createPlugin,
  createReactExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { configCatApiRef, configCatApi } from './api/configCat';

export const configCatPlugin = createPlugin({
  id: 'config-cat',
  apis: [
    createApiFactory({
      api: configCatApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) => configCatApi({ discoveryApi, fetchApi }),
    }),
  ],
});

export const ConfigCatFeatureFlag = configCatPlugin.provide(
  createReactExtension({
    name: 'ChangeContent',
    component: {
      lazy: () => import('./components/ConfigCatFeatureFlag').then(m => m.ConfigCatFeatureFlag),
    },
  }),
);
