import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  DomainPicker,
  DomainPickerSchema,
} from './domainFieldDataExtension';

export const DomainPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: DomainPicker,
    name: 'DomainPicker',
    schema: DomainPickerSchema,
  }),
);
