import { DiscoveryApi, createApiRef, FetchApi } from '@backstage/core-plugin-api';
import { SettingValue } from 'configcat-js';

type ConfigCatApiOptions = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
};

export const configCatApiRef = createApiRef<ConfigCatApi>({
  id: 'plugin.config-cat.service',
});

export type ConfigCatApi = ReturnType<typeof configCatApi>;

export const configCatApi = ({ discoveryApi, fetchApi }: ConfigCatApiOptions) => {
  const getFeatureFlag = async (flag: string) => {
    return getValue(flag, false);
  };

  const getValue = async <T extends SettingValue>(key: string, defaultValue: T) => {
    const BASE_URL = await discoveryApi.getBaseUrl('config-cat');

    const url = new URL(`${BASE_URL}/get-value/${key}`);

    url.searchParams.set('defaultValue', JSON.stringify(defaultValue));

    const response = await fetchApi.fetch(url.toString());

    const { value } = await response.json();

    return (value as T) ?? defaultValue;
  };

  return {
    getFeatureFlag,
    getValue,
  };
};
