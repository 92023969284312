import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'trebuchet',
});

export const manifestDetailsRouteRef = createRouteRef({
  id: 'trebuchet-details',
  params: ['manifestId']
});
