import { z } from 'zod';
import { makeFieldSchemaFromZod } from '../utils';

/**
 * @public
 */
export const domainQueryFilterExpressionSchema = z.record(
  z
    .string()
    .or(z.object({ exists: z.boolean().optional() }))
    .or(z.array(z.string())),
);

/**
 * @public
 */
export const DomainPickerFieldSchema = makeFieldSchemaFromZod(
  z.array(z.string()),
);

/**
 * The input props that can be specified under `ui:options` for the
 * `EntityPicker` field extension.
 *
 * @public
 */
export type DomainPickerUiOptions =
  typeof DomainPickerFieldSchema.uiOptionsType;

export type DomainPickerProps = typeof DomainPickerFieldSchema.type;

export const DomainPickerSchema = DomainPickerFieldSchema.schema;

export type DomainPickerFilterQuery = z.TypeOf<
  typeof domainQueryFilterExpressionSchema
>;

export type DomainPickerFilterQueryValue =
DomainPickerFilterQuery[keyof DomainPickerFilterQuery];
