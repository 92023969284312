import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { trebuchetBackendApiRef, trebuchetBackendApi } from './api/trebuchet';

import { rootRouteRef } from './routes';

export const trebuchetPlugin = createPlugin({
  id: 'trebuchet',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: trebuchetBackendApiRef,
      deps: { configApi: configApiRef, discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ configApi, discoveryApi, fetchApi }) =>
        trebuchetBackendApi({ configApi, discoveryApi, fetchApi }),
    }),
  ],
});

export const ChangeContent = trebuchetPlugin.provide(
  createRoutableExtension({
    name: 'ChangeContent',
    component: () => import('./components/ChangeContent').then(m => m.ChangeContent),
    mountPoint: rootRouteRef,
  }),
);

export const ChangeListPage = trebuchetPlugin.provide(
  createRoutableExtension({
    name: 'ChangePage',
    component: () => import('./pages/ChangeList').then(m => m.ChangePage),
    mountPoint: rootRouteRef,
  }),
);

export const ChangeDetailsPage = trebuchetPlugin.provide(
  createRoutableExtension({
    name: 'ChangeDetails',
    component: () => import('./pages/ChangeDetails').then(m => m.ChangeDetails),
    mountPoint: rootRouteRef,
  }),
);

export const EntityChangeContext = trebuchetPlugin.provide(
  createRoutableExtension({
    name: 'EntityChangeContext',
    component: () => import('./components/EntityChangeContext').then(m => m.EntityChangeContext),
    mountPoint: rootRouteRef,
  }),
);
